<template>
  <div>
    <impaction-points-pares />
    <par />
    <check-par />

    <resolution-zones />
    <check-categories-index />
    <check-categories-impaction-points />
    <flow-types />
    <oligoelements />
    <chakras />
    <chromosomes />
    <psycoemotionals />
    <poisoning />
    <hormonal />
    <spiritual />
    <complementary-therapies />
    <sophrological />
    <movement />
    <basic-protocol />
    <fsmi-protocol />
    <herpes-check />
    <lymphatic-protocol />
    <special-pairs />
  </div>
</template>

<script>
export default {
  components: {
    ImpactionPointsPares: () =>
      import(
        "../../../steps/execute/domains/pares/presentation/dialogs/ImpactionPointsDialog"
      ),
    Par: () =>
      import(
        "../../../steps/execute/domains/pares/presentation/dialogs/ParDialog"
      ),
    CheckPar: () =>
      import(
        "../../../steps/execute/domains/check-pares/presentation/dialogs/CheckParDialog"
      ),
    CheckCategoriesIndex: () =>
      import(
        "../../../steps/execute/domains/check-categories/presentation/dialogs/IndexDialog"
      ),
    CheckCategoriesImpactionPoints: () =>
      import(
        "../../../steps/execute/domains/check-categories/presentation/dialogs/ImpactionPointsDialog"
      ),
    ResolutionZones: () =>
      import(
        "../../../steps/execute/domains/resolution-zones/presentation/dialogs/ResolutionZonesDialog"
      ),
    FlowTypes: () =>
      import(
        "../../../steps/execute/domains/flow-types/presentation/dialogs/IndexDialog"
      ),
    Oligoelements: () =>
      import(
        "../../../steps/execute/domains/oligoelements/presentation/dialogs/IndexDialog"
      ),
    Chakras: () =>
      import(
        "../../../steps/execute/domains/chakras/presentation/dialogs/IndexDialog"
      ),
    Chromosomes: () =>
      import(
        "../../../steps/execute/domains/chromosomes/presentation/dialogs/IndexDialog"
      ),
    Psycoemotionals: () =>
      import(
        "../../../steps/execute/domains/psycoemotionals/presentation/dialogs/IndexDialog"
      ),
    Poisoning: () =>
      import(
        "../../../steps/execute/domains/poisoning/presentation/dialogs/IndexDialog"
      ),
    Hormonal: () =>
      import(
        "../../../steps/execute/domains/hormonal/presentation/dialogs/IndexDialog"
      ),
    Spiritual: () =>
      import(
        "../../../steps/execute/domains/spiritual/presentation/dialogs/IndexDialog"
      ),
    ComplementaryTherapies: () =>
      import(
        "../../../steps/execute/domains/complementary-therapies/presentation/dialogs/IndexDialog"
      ),
    Sophrological: () =>
      import(
        "../../../steps/execute/domains/sophrological/presentation/dialogs/IndexDialog"
      ),
    Movement: () =>
      import(
        "../../../steps/execute/domains/3d-movement-1-2/presentation/dialogs/IndexDialog"
      ),
    BasicProtocol: () =>
      import(
        "../../../steps/execute/domains/basic-protocol/presentation/dialogs/IndexDialog"
      ),
    FsmiProtocol: () =>
      import(
        "../../../steps/execute/domains/fsmi-protocol/presentation/dialogs/IndexDialog"
      ),
    HerpesCheck: () =>
      import(
        "../../../steps/execute/domains/herpes-check/presentation/dialogs/IndexDialog"
      ),
    LymphaticProtocol: () =>
      import(
        "../../../steps/execute/domains/lymphatic-protocol/presentation/dialogs/IndexDialog"
      ),
    SpecialPairs: () =>
      import(
        "../../../steps/execute/domains/special-pairs/presentation/dialogs/IndexDialog"
      ),
  },
  methods: {},
};
</script>

<style></style>
